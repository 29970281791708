import React from "react"
import { Vector3 } from "three"
import { sphericalCoords } from "./sphericalCoords"
import { useCanvasStore } from "@state/canvasStore"
import { useStore } from "@state/store"
import {
  APARTMENT_INDEX,
  CAM_POSITION_INDEX,
  VIEW_ID_INDEX,
} from "@data/cameraData"
import { useThree } from "@react-three/fiber"
import gsap from "gsap"

export const useCamera = () => {
  const cameraData = useCanvasStore((s) => s.cameraData)
  const setCamera = useCanvasStore((s) => s.setCamera)
  const setSpherical = useCanvasStore((s) => s.setSpherical)
  const setCameraData = useCanvasStore((s) => s.setCameraData)
  const setControlsRef = useCanvasStore((s) => s.setControlsRef)
  const selectedUnit = useStore((s) => s.selectedUnit)

  const { invalidate } = useThree()

  const filters = useStore((s) => s.filters)

  React.useEffect(() => {
    setControlsRef(controlsRef.current)
  }, [])

  React.useEffect(() => {
    const logCam = () => {
      const camsstring = `new Vector3(${
        Math.round(cameraRef.current.position.x * 100) / 100
      }, ${Math.round(cameraRef.current.position.y * 100) / 100}, ${
        Math.round(cameraRef.current.position.z * 100) / 100
      })`

      navigator.clipboard.writeText(camsstring)
    }
    window.addEventListener("click", logCam)

    return () => {
      window.removeEventListener("click", logCam)
    }
  }, [])

  const cameraRef = React.useRef<any>(null)
  const controlsRef = React.useRef<any>(null)

  const [activeBlock, setActiveBlock] = React.useState<string | null>(null)

  React.useEffect(() => {
    setActiveBlock(filters?.blocks?.[0] || null)
  }, [filters])

  React.useEffect(() => {
    if (activeBlock) {
      const blockData = CAM_POSITION_INDEX.residences.activeBlock[activeBlock]

      setCameraData(blockData)
    } else {
      setCameraData(CAM_POSITION_INDEX.residences.default)
    }
  }, [activeBlock])

  React.useEffect(() => {
    if (selectedUnit?.[0]) {
      const unit = selectedUnit?.[0]
      const unitName = unit.unitName

      const block = unitName.split("-")[0]

      setCameraData({
        position: APARTMENT_INDEX[VIEW_ID_INDEX[unitName]],
        target: CAM_POSITION_INDEX.residences.activeBlock[block].target,
      })

      return
    } else {
      setCameraData(CAM_POSITION_INDEX.residences.default)
    }
  }, [filters?.blocks, selectedUnit])

  const onChange = (e: any) => {
    if (!cameraRef.current) return
    const { azimuth, polar } = sphericalCoords(cameraRef.current.position)
    setSpherical({ azimuth, polar })

    setCamera(
      new Vector3(
        cameraRef.current.position.x,
        cameraRef.current.position.y,
        cameraRef.current.position.z,
      ),
    )
  }

  const canvasIN = () => {
    gsap.to(canvasRef, {
      delay: `0.25`,
      opacity: 1,
      duration: 0.25,

      onComplete: () => {
        controlsRef?.current?.update()
      },
    })
  }

  const canvasRef = useCanvasStore((s) => s.canvasRef)

  React.useEffect(() => {
    const duration = 2

    const controls = controlsRef.current

    const distance = cameraData.position.distanceTo(cameraRef.current.position)

    if (distance < 3) return

    gsap.to(canvasRef, {
      opacity: 0,
      duration: 0.25,

      onComplete: () => {
        controls.target.set(
          cameraData.target.x,
          cameraData.target.y,
          cameraData.target.z,
        )

        cameraRef.current.position.set(
          cameraData.position.x,
          cameraData.position.y,
          cameraData.position.z,
        )

        controls.update()
        if (!controlsRef?.current || !canvasRef) return
        setTimeout(() => {
          canvasIN()
        }, 250)

        runInvalidation(Date.now(), invalidate)
      },
    })
  }, [cameraData])

  return {
    cameraRef,
    controlsRef,
    onChange,
    cameraData,
  }
}

export const runInvalidation = (startTime, invalidate) => {
  const currentTime = Date.now()

  if (currentTime - startTime >= 2000) {
    return
  }

  requestAnimationFrame(() => {
    invalidate()
    runInvalidation(startTime, invalidate)
  })
}
