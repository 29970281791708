import { useProjectDataStore } from "@state/projectDataStore"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import TextButton from "@common/ui/TextButton"

const ViewSection = ({ aspect, floor, isInFavourites = false }) => {
  const { viewsData } = useProjectDataStore((s) => ({
    viewsData: s.viewsData,
  }))
  const [viewIndex, setViewIndex] = useState(0)

  const aspects = aspect?.split("/")
  const filenames = aspects?.map(
    (aspect) => `L${LEVELKEY[floor]}-${aspect}.webp`,
  )
  const urls = filenames?.map((filename) => {
    const match = viewsData?.viewMedia?.find(
      (obj) => obj?.image?.filename === filename,
    )
    return match?.image?.publicURL
  })
  const { contentControls, syncState } = useStore((s) => ({
    contentControls: s.contentControls,
    syncState: s.syncState,
  }))

  useEffect(() => {
    gsap.to("#view-wrapper", {
      autoAlpha: contentControls?.index === 1 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [contentControls])

  return (
    <Wrapper id="view-wrapper">
      {urls?.map((src, i) => (
        <View
          key={src}
          style={{
            opacity: viewIndex === i ? 1 : 0,
            backgroundImage: `url(${src})`,
            // ...TEMPORARYVIEWTRANSFORM[LEVELKEY[floor]]
          }}
          onClick={() => {
            if (isInFavourites) return

            syncState({
              contentControls: {
                ...contentControls,
                index: 0,
              },
            })
            setTimeout(() => {
              setViewIndex(0)
            }, 400)
          }}
        />
      ))}
      <Controls id="view-section-controls">
        <TextButton
          style={{
            height: "4em",
            minWidth: "4em",
            margin: 0,
          }}
          icon={
            <img
              src="assets/icons/left-arrow.svg"
              alt=""
              style={{ filter: "invert(100%)" }}
            />
          }
          onClick={() => {
            syncState({
              contentControls: {
                ...contentControls,
                index: 0,
              },
            })
            setTimeout(() => {
              setViewIndex(0)
            }, 400)
          }}
        />
        {aspects?.map((aspect, i) => {
          return (
            <TextButton
              key={aspect}
              // color="secondary"
              style={{
                height: "4em",
                width: "calc(50% - 2em)",
                margin: 0,
                opacity: viewIndex === i ? 1 : 0.5,
              }}
              onClick={() => setViewIndex(i)}
            >
              {aspect}
            </TextButton>
          )
        })}
      </Controls>
    </Wrapper>
  )
}

export default ViewSection

const Wrapper = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const View = styled.div`
  width: 100%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s ease-in-out;
  background-position: 50% 55%;
  background-size: auto 140%;
`

const Controls = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  z-index: 10;
  width: calc(45% - 6rem);
`

const LEVELKEY = {
  "2": 4,
  "3": 4,
  "4": 4,
  "5": 6,
  "6": 6,
  "7": 8,
  "8": 8,
  "9": 10,
  "10": 10,
  "11": 12,
  "12": 12,
  "13": 14,
  "14": 14,
}

// const TEMPORARYVIEWTRANSFORM = {
//   4: {
//     backgroundPosition: "center",
//     backgroundSize: "auto 120%",
//   },
//   6: {
//     backgroundPosition: "50% 55%",
//     backgroundSize: "auto 140%",
//   },
//   8: {
//     backgroundPosition: "center",
//     backgroundSize: "auto 120%",
//   },
//   10: {
//     backgroundPosition: "center",
//     backgroundSize: "auto 120%",
//   },
//   12: {
//     backgroundPosition: "center",
//     backgroundSize: "auto 120%",
//   },
//   14: {
//     backgroundPosition: "50% 60%",
//     backgroundSize: "auto 130%",
//   },
// }
