import styled from "styled-components"
import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"
import TextButton from "@common/ui/TextButton"
import { Cross2Icon } from "@radix-ui/react-icons"
import { FC } from "react"
import ViewSection from "../ViewSection"

type ImageDisplayCompareProps = {
  show?: any
  selectedUnit: any
}

const ImageDisplayCompare: FC<ImageDisplayCompareProps> = ({
  show,
  selectedUnit,
}) => {
  const contentControls = useStore((s) => s.contentControls)

  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))

  const colors = brandingData
  const aspect = selectedUnit?.aspect
  const floor = selectedUnit?.floor
  const baseUrl = "/payload/floorPlanMedia/"
  const image = selectedUnit?.unitType?.replaceAll("/", "")

  return (
    <Wrapper colors={colors}>
      {show && (
        <TextButton
          color="#fff"
          className="close-button"
          onClick={() => {
            console.log("CLOSE")
            show(false)
          }}
          style={{ borderRadius: "50%" }}
          icon={<Cross2Icon color="#fff" />}
          iconPosition="right"
          variant="outline"
        />
      )}
      {contentControls?.index === 0 ? (
        <Image src={`${baseUrl}${image}.webp`} alt="" isview={false} />
      ) : (
        <ViewSection aspect={aspect} floor={floor} isInFavourites={true} />
      )}
    </Wrapper>
  )
}

export default ImageDisplayCompare

const Wrapper = styled.div<{ colors: any }>`
  width: 100%;
  height: 90%;
  position: relative;
  background-color: var(--secondary);
  display: flex;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 2em;
    left: 2em;
    z-index: 1000000;
  }

  .view-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }
  #view-wrapper {
    background-color: transparent;
  }
  #view-section-controls {
    bottom: 0;
    left: 0;
    transform-origin: 0% 100%;
    transform: scale(0.8);

    & div {
      background-color: var(--green);
      color: var(--white);

      &:nth-of-type(1) {
        display: none;
      }
      &:nth-of-type(2),
      :nth-of-type(3) {
        width: 50% !important;
      }
    }
    & img {
      filter: none !important;
    }
  }

  @media (max-width: 480px) {
    .close-button {
      border: 0.1rem solid white;
      & svg {
        width: 6px !important;
      }
    }
  }
  @media (min-width: 2051px) and (max-width: 3839px) {
    .close-button {
      border: 0.1rem solid white;
      & svg {
        height: auto;
        width: 25px !important;
      }
    }
  }
  @media (min-width: 3840px) {
    .close-button {
      border: 0.1rem solid white;
      & svg {
        height: auto;
        width: 50px !important;
      }
    }
  }
`

const Image = styled.img<{ isview: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isview ? "cover" : "contain")};
  padding: ${(props) => (props.isview ? "8em 4em" : "2em")};
`
