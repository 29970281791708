import { useStore } from "@state/store"
import { filterTable, getFiltersDynamically } from "@lib/utils/residenceTableFn"
import { useEffect, useMemo, useState, useCallback } from "react"
import { temp_data } from "@data/temp_data"

export type TFilters = {
  floor: number[]
  price: number[]
  beds: string[]
  baths: string[]
  blocks: string[]
}

export const useFilters = () => {
  const [availabilityData, setAvailabilityData] = useState([])
  const [initialFilters, setInitialFilters] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const activeType = useStore((s) => s.activeType)
  const selectedUnit = useStore((s) => s.selectedUnit)
  const filters = useStore((s) => s.filters)
  const { syncState } = useStore((s) => ({ syncState: s.syncState }))

  // Helper to parse area values that contain both sqm and sqft
  const parseAreaValue = useCallback((areaString) => {
    if (!areaString) return { sqm: "", sqft: "" }

    const sqmMatch = areaString.match(/(\d+\.?\d*)m²/)
    const sqftMatch = areaString.match(/(\d+\.?\d*)\s*ft²/)

    return {
      sqm: sqmMatch ? sqmMatch[1] : "",
      sqft: sqftMatch ? sqftMatch[1] : "",
    }
  }, [])

  // Parse TSV data function
  const parseTSV = useCallback(
    (tsvData) => {
      try {
        const rows = tsvData.trim().split("\n")
        const headers = rows[0].split("\t").map((header) => header.trim())

        return rows
          .slice(1)
          .map((row) => {
            const values = row.split("\t")
            const rawUnit = {}

            headers.forEach((header, index) => {
              rawUnit[header] =
                index < values.length ? values[index]?.trim() || null : null
            })

            // Map TSV data to match expected structure
            return {
              "Flat Number": rawUnit["Flat Number"] || "",
              "Plot Name": rawUnit["Plot Name"] || "",
              viewID: rawUnit["View ID"] || rawUnit["viewID"] || "",
              aspect: rawUnit["Aspect"] || rawUnit["aspect"] || "",
              unitName: rawUnit["Unit Name"] || rawUnit["unitName"] || "",
              unitType: rawUnit["Unit Type"] || rawUnit["unitType"] || "",
              fileName: null,
              status: rawUnit["Status"] || rawUnit["status"] || "Active",
              availability:
                rawUnit["Availability"] || rawUnit["availability"] || "",
              "Flat Floor Level": rawUnit["Flat Floor Level"] || "",
              beds: rawUnit["Beds"] || rawUnit["beds"] || "",
              floor: rawUnit["Flat Floor Level"] || rawUnit["floor"] || "",
              "Floor Area Sq. Ft.":
                rawUnit["Floor Area Sq. Ft."] ||
                rawUnit["Floor Area Sq Ft"] ||
                "",
              "Floor Area Sq. M.":
                rawUnit["Floor Area Sq. M."] ||
                rawUnit["Floor Area Sq M"] ||
                "",
              // Add numeric-only versions for easier filtering/sorting
              "Floor Area (sqft)": (
                rawUnit["Floor Area Sq. Ft."] ||
                rawUnit["Floor Area Sq Ft"] ||
                ""
              ).replace(/[^\d.]/g, ""),
              "Floor Area (sqm)": (
                rawUnit["Floor Area Sq. M."] ||
                rawUnit["Floor Area Sq M"] ||
                ""
              ).replace(/[^\d.]/g, ""),
              price: rawUnit["Price"] || rawUnit["price"] || "",
              "Price qualifier":
                rawUnit["Price qualifier"] || rawUnit["Price Qualifier"] || "",
              "Current 1st Tranche %": rawUnit["Current 1st Tranche %"] || "",
              "1st tranche value":
                rawUnit["1st tranche value"] ||
                rawUnit["1st Tranche Value"] ||
                "",
              "Max Percentage Tranche": rawUnit["Max Percentage Tranche"] || "",
              Deposit: rawUnit["Deposit"] || "",
              "Monthly Service Charge": rawUnit["Monthly Service Charge"] || "",
              "Monthly Rent (SO)":
                rawUnit["Monthly Rent (SO)"] || rawUnit["Monthly Rent"] || "",
              "Monthly Mortgage": rawUnit["Monthly Mortgage"] || "",
              "Total Monthly Costs": rawUnit["Total Monthly Costs"] || "",
              "Length of Lease": rawUnit["Length of Lease"] || "",
              Kitchen: rawUnit["Kitchen"] || "",
              "Kitchen/Dining": rawUnit["Kitchen/Dining"] || "",
              "Living/Dining": rawUnit["Living/Dining"] || "",
              Living: rawUnit["Living"] || "",
              "Bedroom 1": rawUnit["Bedroom 1"] || "",
              "Bedroom 2": rawUnit["Bedroom 2"] || "",
              "Total Internal Area (sqm)":
                parseAreaValue(rawUnit["Total Internal Area"]).sqm + " m²",
              "Total Internal Area (sqft)":
                parseAreaValue(rawUnit["Total Internal Area"]).sqft + " ft²",
              "Total Internal Area": rawUnit["Total Internal Area"] || "",
              "Total Terrace Area (sqm)":
                parseAreaValue(
                  rawUnit["Total Terrace Area"] ||
                    rawUnit["Total Terrace Area\r"],
                ).sqm !== ""
                  ? parseAreaValue(rawUnit["Total Terrace Area"]).sqm + " m²"
                  : "",
              "Total Terrace Area (sqft)":
                parseAreaValue(
                  rawUnit["Total Terrace Area"] ||
                    rawUnit["Total Terrace Area\r"],
                ).sqft !== ""
                  ? parseAreaValue(rawUnit["Total Terrace Area"]).sqft + " ft²"
                  : "",
              "Total Terrace Area\r":
                rawUnit["Total Terrace Area"] ||
                rawUnit["Total Terrace Area\r"] ||
                "",
            }
          })
          .filter((unit) => unit["Flat Number"] && unit["Plot Name"])
      } catch (error) {
        console.error("Error parsing TSV:", error)
        return []
      }
    },
    [parseAreaValue],
  )

  // Fetch data from Google Sheets
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vQJK1zNAu0OguHotumVzCK2nnM9O8Aq_SBqg3iwj31_xceNb3Kvr54B2VwUGolIojKRgHWugA3uTDOF/pub?gid=1001685203&single=true&output=tsv",
        )

        if (!response.ok) {
          throw new Error("Failed to fetch apartments")
        }

        const tsvData = await response.text()
        const validData = parseTSV(tsvData)

        if (validData.length > 0) {
          setAvailabilityData(validData)
        } else {
          // Fallback to temp_data if no valid data
          setAvailabilityData(temp_data)
        }
      } catch (error) {
        console.error("Error fetching apartments data:", error)
        setAvailabilityData(temp_data)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [parseTSV])

  // Generate dynamic filters based on availability data
  const dynamicFilters = useMemo(() => {
    const filters = getFiltersDynamically(availabilityData)
    setInitialFilters(filters)
    return filters
  }, [availabilityData])

  // Update filtered units when filters or active type changes
  useEffect(() => {
    const filteredData = filterTable(filters, availabilityData, activeType)
    syncState({ filteredUnits: filteredData })
  }, [filters, activeType, availabilityData, syncState])

  // Update blocks filter when selected unit changes
  useEffect(() => {
    if (selectedUnit && selectedUnit.length > 0) {
      const unit = selectedUnit[0]
      const block = unit.unitName.split("-")[0]

      if (!block) return

      // Only update if block isn't already in filters
      if (filters.blocks && !filters.blocks.includes(block)) {
        syncState({
          filters: {
            ...filters,
            blocks: [...(filters.blocks || []), block],
          },
        })
      }
    }
  }, [selectedUnit, filters, syncState])

  // Handler functions
  const handleReset = useCallback(() => {
    syncState({ filters: initialFilters })
  }, [initialFilters, syncState])

  const handleToggleChange = useCallback(
    (key: keyof TFilters, value: any, onlyOneSelectable: boolean) => {
      const currentValues = filters[key] || []

      const updatedValues = onlyOneSelectable
        ? currentValues.includes(value)
          ? []
          : [value]
        : currentValues.includes(value)
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value]

      syncState({
        filters: {
          ...filters,
          [key]: updatedValues,
        },
      })
    },
    [filters, syncState],
  )

  const handleRangeChange = useCallback(
    (key: keyof TFilters, value: any) => {
      syncState({
        filters: {
          ...filters,
          [key]: value,
        },
      })
    },
    [filters, syncState],
  )

  return {
    initialFilters,
    dynamicFilters,
    handleToggleChange,
    filters,
    handleRangeChange,
    handleReset,
    isLoading,
    availabilityData,
  }
}
