import styled from "styled-components"
import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"

export const poundFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const ResidenceInfoCard2 = ({ unit }) => {
  const contentControls = useStore((s) => s.contentControls)
  const residencePopup = useStore((s) => s.residencePopup)

  // this is for compare mode
  if (!Array.isArray(unit)) {
    unit = [unit]
  }

  const selectedUnit = unit[0]
  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))

  const bgImage = brandingData?.bgImage?.publicURL

  if (!selectedUnit || contentControls?.index === null) return null
  let name = selectedUnit?.unitName
  name = name.split("-")
  let block = name[0]
  const marketingName = block === "S01C" ? "Cherry Point" : "Oak House"
  name = Number(`${name[1]}${name[2]}`)
  let type = selectedUnit?.unitType
  type = type.split(" ").filter((txt) => txt !== "TYPE")
  type = type.join("").replace("1-STUDIO", "1-ST")

  return (
    <Wrapper
      id="residenceInfoCard"
      bgImage={bgImage}
      style={{
        transform: residencePopup ? "translateX(0%)" : "translate(-100%)",
      }}
    >
      <div className="specsContainer">
        <span className="unitAvailability">{selectedUnit?.availability}</span>
        <div className="UnitID">
          <div className="unitName">
            <span style={{ color: "var(--secondary)" }}>{name}</span>{" "}
            {marketingName}
          </div>
          {selectedUnit?.beds < 1 ? (
            <div className="beds">Studio</div>
          ) : (
            <div className="beds">{selectedUnit?.beds} bedroom</div>
          )}
        </div>
        <svg
          width="28"
          height="22"
          viewBox="0 0 28 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginBottom: "2rem" }}
        >
          <path
            d="M26.16 0H1L13.86 21.86L26.16 0Z"
            fill="var(--secondary)"
            fillOpacity={0.9}
          />
        </svg>
        <div className="spec coloredSpec">
          <div>Asking Price</div>
          <div className="spec-info">
            {poundFormatter.format(selectedUnit?.price)}{" "}
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>Total Area</div>
          <div className="spec-info">
            {selectedUnit?.["Floor Area Sq. M."]} sqm
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>Scheme</div>
          <div className="spec-info">{selectedUnit?.["Price qualifier"]}</div>
        </div>
        <div className="spec coloredSpec">
          <div>1st. Tranche</div>
          <div className="spec-info">
            {selectedUnit?.["Current 1st Tranche %"]}%
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>1st. Tranche Value</div>
          <div className="spec-info">
            {poundFormatter.format(selectedUnit?.["1st tranche value"])}
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>Deposit</div>
          <div className="spec-info">
            {poundFormatter.format(selectedUnit?.["Deposit"])}
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>Max. Tranche</div>
          <div className="spec-info">
            {selectedUnit?.["Max Percentage Tranche"]}%
          </div>
        </div>
      </div>

      <div className="financialContainer">
        <div className="spec">
          <div>Aspect</div>
          <div className="spec2">{selectedUnit?.aspect}</div>
        </div>
        <div className="spec">
          <div>Beds</div>
          <div className="spec2">{selectedUnit?.beds}</div>
        </div>
        <div className="spec">
          <div>Floor</div>
          <div className="spec2">{selectedUnit?.floor}</div>
        </div>
        <div className="spec">
          <div>Monthly Service Charge</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.["Monthly Service Charge"]) ??
              "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Monthly Rent</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.["Monthly Rent (SO)"]) ??
              "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Monthly Mortgage</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.["Monthly Mortgage"]) ??
              "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Total Monthly Cost</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.["Total Monthly Costs"]) ??
              "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Length of Lease</div>
          <div className="spec2">
            {selectedUnit?.["Length of Lease"] ?? "Coming Soon"} years
          </div>
        </div>
      </div>
      <div className="financialContainer">
        {dimensionKeys.reduce((acc, key, index, array) => {
          if (selectedUnit?.[key]) {
            // Add the current spec element
            acc.push(
              <div className="spec" key={`dim-${key}`}>
                <div>{key}</div>
                <div className="spec2 dimension">{selectedUnit[key]}</div>
              </div>,
            )

            // Add a spacer after every 2nd element (except the last one)
            if (index % 2 === 1 && index < array.length - 1) {
              acc.push(<div className="spacer" key={`spacer-${index}`} />)
            }
          }
          return acc
        }, [])}
      </div>
    </Wrapper>
  )
}

export default ResidenceInfoCard2

const Wrapper = styled.div<{ bgImage: any }>`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: #fff;
  width: 45%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: transform 0.4s ease-in-out;
  transform: translateX(-100%);
  font-size: 3.5rem !important;
  color: var(--primary) !important;
  padding: 2rem;

  .unitAvailability {
    padding: 0.2rem 0.75rem;
    border-radius: 0.5rem;
    background-color: var(--secondary);
    color: var(--white);
    font-size: 0.4em;
    font-weight: 800;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5em;
    width: fit-content;
  }

  .UnitID {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 1em;
    font-weight: 800;
    margin-bottom: 2.5rem;

    .unitName {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3em;
    }

    .beds {
      font-size: 0.7em;
      font-family: secondaryFont;
      color: var(--secondary);
      text-transform: capitalize;
      letter-spacing: 0.05em;
    }
  }

  .floor {
    font-size: 0.3em;
    letter-spacing: 0.2em;
  }

  .specsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;

    .spec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 200;
      letter-spacing: 0.3em;
      font-size: 0.3em;
    }

    .coloredSpec {
      color: var(--secondary);
    }

    .spec-info {
      font-size: 1.2em;
      letter-spacing: 0.2em;
      font-weight: 800;
    }
  }

  .financialContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;

    .spec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      letter-spacing: 0.3em;
      font-size: 0.3em;
    }

    .spec2 {
      font-size: 1.2em;
      letter-spacing: 0.2em;
      font-weight: 800;
    }

    .spacer {
      height: 1rem;
    }
  }

  .copy {
    font-size: 0.3em;
    line-height: 1.5em;
    text-transform: none;
    width: 100%;
    text-align: left;
    opacity: 0.5;
    letter-spacing: 0.1em;
    padding: 0 2rem;
    transform: translateY(70%);
  }

  .dimension {
    word-spacing: 0.2em !important;
    letter-spacing: 0 !important;
    text-transform: none;
  }
`

const dimensionKeys = [
  "Kitchen",
  "Kitchen/Dining",
  "Living",
  "Living/Dining",
  "Bedroom 1",
  "Bedroom 2",
  "Total Internal Area (sqft)",
  "Total Internal Area (sqm)",
  "Total Terrace Area (sqft)",
  "Total Terrace Area (sqm)",
]
