import type React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import * as Slider from "@radix-ui/react-slider"
import TextButton from "@common/ui/TextButton"
import ToggleGroup from "@common/ui/ToggleGroup"

import { useFilters } from "@common/hooks/useFilteredData"
import { useStore } from "@state/store"

const formatPrice = (val: number) => {
  const formattedVal = Number(val).toLocaleString()
  return formattedVal
}

// TODO - Should come from payload
const marketingNames = {
  S01C: "Cherry Point",
  N01B: "Oak House",
}

const bedroomDisplayNames = {
  "0": "Studio",
  "1": "1 Bed",
  "2": "2 Bed",
}

type ResidenceFiltersProps = {
  className?: string
  show: boolean
}

const Filters: React.FC<ResidenceFiltersProps> = ({ className }) => {
  const {
    initialFilters,
    dynamicFilters,
    handleToggleChange,
    handleRangeChange,
    filters,
    handleReset,
  } = useFilters()
  const { floorRange, priceRange, bedOptions, blockOptions } = dynamicFilters
  const syncState = useStore((s) => s.syncState)
  const activeType = useStore((s) => s.activeType)

  // Initialize with safe default values
  const [localPrice, setLocalPrice] = useState<[number, number]>([0, 1])
  const [localFloor, setLocalFloor] = useState<[number, number]>([0, 1])

  // Track if we have valid data
  const [hasValidData, setHasValidData] = useState(false)

  // Update local state when dynamic filters change
  useEffect(() => {
    const isPriceValid =
      priceRange[0] !== Number.POSITIVE_INFINITY &&
      priceRange[1] !== Number.NEGATIVE_INFINITY &&
      !isNaN(priceRange[0]) &&
      !isNaN(priceRange[1])

    const isFloorValid =
      floorRange[0] !== Number.POSITIVE_INFINITY &&
      floorRange[1] !== Number.NEGATIVE_INFINITY &&
      !isNaN(floorRange[0]) &&
      !isNaN(floorRange[1])

    if (isPriceValid) {
      setLocalPrice(priceRange)
    }

    if (isFloorValid) {
      setLocalFloor(floorRange)
    }

    setHasValidData(isPriceValid && isFloorValid)
  }, [priceRange, floorRange])

  return (
    <FiltersWrapper className={className}>
      <div className="buildings">
        <div className="label">Building</div>
        <ToggleGroup
          options={blockOptions}
          labels={marketingNames}
          selected={filters.blocks}
          type="blocks"
          onClick={(value: string) => {
            handleToggleChange("blocks", value, true)
          }}
        />
      </div>
      <div className="price">
        <div className="label">Price</div>
        <div className="filter">
          <div className="price-values">
            <div className="price-value">
              {hasValidData ? `£${formatPrice(localPrice[0])}` : "Loading..."}
            </div>
            <div className="price-value">
              {hasValidData ? `£${formatPrice(localPrice[1])}` : "Loading..."}
            </div>
          </div>
          {hasValidData && (
            <form>
              <Slider.Root
                value={localPrice}
                className="SliderRoot"
                step={10000}
                min={priceRange[0]}
                max={priceRange[1]}
                onValueChange={(value) => {
                  setLocalPrice(value as [number, number])
                }}
                onValueCommit={(value) => {
                  handleRangeChange("price", value)
                }}
              >
                <Slider.Track className="SliderTrack">
                  <Slider.Range className="SliderRange" />
                </Slider.Track>
                <Slider.Thumb className="SliderThumb" aria-label="Min price" />
                <Slider.Thumb className="SliderThumb" aria-label="Max price" />
              </Slider.Root>
            </form>
          )}
        </div>
      </div>
      <div className="floors">
        <div className="label">Floor</div>
        <div className="filter">
          <div className="floor-values">
            <div className="floor-value">
              {hasValidData ? localFloor[0] : "Loading..."}
            </div>
            <div className="floor-value">
              {hasValidData ? localFloor[1] : "Loading..."}
            </div>
          </div>
          {hasValidData && (
            <form>
              <Slider.Root
                className="SliderRoot"
                value={localFloor}
                minStepsBetweenThumbs={1.5}
                step={1}
                min={floorRange[0]}
                max={floorRange[1]}
                onValueChange={(value) => {
                  setLocalFloor(value as [number, number])
                }}
                onValueCommit={(value) => {
                  handleRangeChange("floor", value)
                }}
              >
                <Slider.Track className="SliderTrack">
                  <Slider.Range className="SliderRange" />
                </Slider.Track>
                <Slider.Thumb className="SliderThumb" />
                <Slider.Thumb className="SliderThumb" />
              </Slider.Root>
            </form>
          )}
        </div>
      </div>
      <div className="beds">
        <div className="label">Bedrooms</div>
        <ToggleGroup
          labels={bedroomDisplayNames}
          options={bedOptions}
          selected={filters.beds}
          type="beds"
          onClick={(value: string) => {
            handleToggleChange("beds", value, false)
          }}
        />
      </div>
      <div className="label">{activeType}</div>

      <TextButton
        style={{
          width: "8rem",
          margin: "1.5rem 0",
          border: "1px solid white",
          color: filters === initialFilters ? "white" : "var(--primary)",
          backgroundColor:
            filters === initialFilters ? "var(--primary)" : "var(--secondary)",
        }}
        variant="outline"
        onClick={() => {
          syncState({
            activeType: null,
          })
          if (hasValidData) {
            setLocalFloor(floorRange)
            setLocalPrice(priceRange)
          }
          handleReset()
        }}
        className="reset-button"
      >
        Reset Filters
      </TextButton>
    </FiltersWrapper>
  )
}

export default Filters

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem 2rem 2em;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border: 1px solid white;
  color: #fff;
  overflow-y: auto; /* Allow scrolling if content overflows */

  .label {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 1rem 0;
    letter-spacing: 0.15em;
    color: white;
  }

  .floors,
  .price,
  .beds,
  .buildings {
    flex-grow: 1; /* Allow these sections to grow */
    flex-shrink: 1; /* Allow these sections to shrink */
    flex-basis: 0; /* Equally distribute space among sections */
  }

  .SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    height: 2rem;
  }

  .SliderTrack {
    background-color: #ffffff49;
    position: relative;
    flex-grow: 1;
    height: 1px;
  }

  .SliderRange {
    background-color: white;
    position: absolute;
    height: 1px;
  }

  .SliderThumb {
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--secondary);
    border: 1px solid white;
  }

  .floors .filter,
  .price .filter {
    color: white;
  }

  .baths .filter,
  .buildings .filter,
  .beds .filter,
  .view .filter {
    width: 100%;
    display: flex;
    border: 1px solid white;
    font-weight: bold;
    color: white;

    button {
      all: unset;
    }

    .option {
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      padding: 0.75rem 0;
      font-size: 0.8rem;
      cursor: pointer;
      transition: 0.3s;
    }

    .option:not(:last-child) {
      border-right: 1px solid white;
      transition: 0.3s;
    }

    .option.selected {
      background-color: var(--secondary);
      color: var(--primary);
      transition: 0.3s;
    }
  }

  .floors .floor-values,
  .price .price-values {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  @media (max-width: 480px) {
    .SliderThumb {
      width: 6px;
      height: 6px;
    }
    .label,
    .reset-button,
    .floors .floor-values,
    .price .price-values,
    .beds .filter .option {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 720px) {
    .SliderThumb {
      width: 8px;
      height: 8px;
    }
    .label,
    .reset-button,
    .floors .floor-values,
    .price .price-values,
    .beds .filter .option {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1080px) {
    .SliderThumb {
      width: 10px;
      height: 10px;
    }
    .label,
    .reset-button,
    .floors .floor-values,
    .price .price-values,
    .beds .filter .option {
      font-size: 1.2rem;
    }
  }
  @media (min-width: 2501px) and (max-width: 3839px) {
    .SliderThumb {
      width: 16px;
      height: 16px;
    }
    .label,
    .reset-button,
    .floors .floor-values,
    .price .price-values,
    .beds .filter .option {
      font-size: 1rem;
    }
  }
  @media (min-width: 3840px) {
    .SliderThumb {
      width: 30px;
      height: 30px;
    }
    .label,
    .reset-button,
    .floors .floor-values,
    .price .price-values,
    .beds .filter .option {
      font-size: 1rem;
    }
  }
`
