import styled from "styled-components/macro"

const Favourites = ({ children }) => {
  return <FavouritesWrapper>{children}</FavouritesWrapper>
}

const FavouritesWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  width: 100%;
  background-color: var(--primary);

  .favourites-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .no-favourites {
      font-size: 2em;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      span {
        color: #50a49c;
        font-style: italic;
        text-transform: lowercase;
      }
    }

    .switch {
      button {
        all: unset;
      }

      .SwitchRoot {
        width: 40px;
        height: 22px;
        background-color: #666666;
        border-radius: 9999px;
        position: relative;
        -webkit-tap-highlight-color: transparent;

        &[data-state="checked"] {
          background-color: #50a49c;
        }
      }

      .SwitchThumb {
        display: block;
        width: 19px;
        height: 15px;
        background-color: white;
        border-radius: 9999px;
        transition: transform 100ms;
        transform: translateX(4px);
        will-change: transform;

        &[data-state="checked"] {
          transform: translateX(17px);
        }
      }

      .Label {
        color: white;
        font-size: 15px;
        line-height: 1;
      }
    }

    .favourites-table {
      width: 70%;
      height: 100%;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      justify-content: center;
      align-items: center;
      padding: 3em;
      font-weight: 700;

      .fav-title {
        width: 100%;
        height: 100%;
        height: 40%;
        min-height: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        & h1 {
          line-height: 4rem;

          :nth-of-type(1) {
            font-size: 5rem;
            color: #fff;
            font-weight: 700;
          }
          :nth-of-type(2) {
            font-size: 4rem;
            color: var(--green);
            font-family: SecondaryFont;
            font-weight: 100;
            letter-spacing: 0.3rem;
          }
        }
      }

      .table {
        width: 100%;
        height: 60%;
        max-height: 80%;
        border: 1px solid #fff;
        padding: 2em 1em;
      }

      .switch {
        margin-top: auto;
        margin-left: auto;
      }

      .flex-table-header {
        background-color: #50a49c;
      }

      .grid-table-row {
        border-bottom: 1px solid #fff;
      }

      .favourites-control-index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .buttons {
          height: 3em;
          border: 1px solid #fff;
          background-color: rgba(255, 255, 255, 0.2);
          color: white;
          cursor: pointer;
          flex: 1;
          transition: all 0.3s ease;

          &.active {
            background-color: #50a49c;
          }
        }
      }

      .table-bottom-controls {
        display: flex;
        width: 100%;
        gap: 1em;

        .bottom-controls-button:last-child {
          margin-left: auto;
        }

        .clear-favorites {
          display: flex;
          gap: 1em;

          .prompt {
            display: flex;
            gap: 1em;
            margin-left: -2em;
            opacity: 0;
            transition: all 0.3s ease-in-out;

            &.active {
              opacity: 1;
              margin-left: 0em;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }

    .favourites-info {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: var(--secondary);

      .unit-compare-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .unit-top {
          border-bottom: 2px solid #fff;
          width: 100%;
          height: 50%;
        }
        .unit-bottom {
          width: 100%;
          height: 50%;
        }
      }
    }

    .unit-single-container {
      height: 100%;
      width: 100%;

      .unit-single {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .info-card-container,
        .image-display-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .info-card-container {
          height: 40%;
        }
        .image-display-container {
          height: 55%;
          padding: 3rem;
        }
      }
    }
  }
`

export default Favourites
