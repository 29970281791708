import styled from "styled-components/macro"

const Residences = ({ children }) => {
  return <ResidencesPageWr>{children}</ResidencesPageWr>
}

const ResidencesPageWr = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--primary);

  .residencesCategory {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    width: calc(45% - 6rem);
    height: 4em;
    display: flex;
    flex-direction: row;
    border: 0.5px solid var(--primary);
    transition: transform 0.4s ease-in-out;
  }
  .residencesCategoryIndex {
    width: 100%;
    height: calc(4em - 1px);
    display: flex;
    flex-direction: row;

    .buttons {
      background-color: transparent;
      color: var(--primary);
      padding: 0.75rem 4rem;
      width: 100%;
    }

    .active {
      background-color: var(--primary);
      color: white;
    }
  }

  .canvas_view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 0;
  }

  .filters-container {
    position: absolute;
    top: 7vh;
    bottom: 7vh;
    width: 23vw;
    padding: 2rem;
    border-right: 3px solid white;
    max-height: calc(100vh - 14vh);
    display: flex;
    align-items: flex-end;
    overflow-y: auto;
    z-index: 2;

    @media (max-width: 1400px) {
      width: 26vw;
    }
  }

  .residences-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 12;
    pointer-events: none;

    > * {
      pointer-events: all;
    }

    .backButton {
      position: absolute;
      top: 15%;
      left: 2rem;
    }
  }

  .externalBtn {
    position: absolute;
    z-index: 9;
    bottom: 2rem;
    left: 50%;
    width: 15% !important;
    transform: translateX(-50%);
    pointer-events: all;
  }

  @media (min-width: 721px) and (max-width: 1080px) {
    #residenceInfoCard {
      font-size: 4.5rem !important;
    }
  }
`

export default Residences
