import React, { useMemo } from "react"
import { TableRow } from "./TableRow"
import styled from "styled-components/macro"

type TableBodyProps = {
  uniqueKey: string
  data: any[]
  cells: any[]
  selectedRows: any[]
  handleRowClick: (row: any) => void
  columns: number
  onPointerOver?: (e: any, type: any) => void
  onPointerOut?: (e: any, type: any) => void
}

const TableBody: React.FC<TableBodyProps> = ({
  uniqueKey,
  data,
  cells,
  selectedRows,
  handleRowClick,
  columns,
  onPointerOver,
  onPointerOut,
}) => {
  const selectedRowKeys = useMemo(
    () => new Set(selectedRows.map((row) => row[uniqueKey])),
    [selectedRows, uniqueKey],
  )

  const isRowSelected = (row: any) => selectedRowKeys.has(row[uniqueKey])

  return (
    <TableBodyContainer id="table-rows-container">
      {data.map((row: any, index: number) => (
        <TableRow
          key={row[uniqueKey]}
          row={row}
          rowIndex={index}
          cells={cells}
          isSelected={isRowSelected(row)}
          onClick={() => handleRowClick(row)}
          onPointerOver={(e) => onPointerOver && onPointerOver(e, row)}
          onPointerOut={(e) => onPointerOut && onPointerOut(e, row)}
          columns={columns}
        />
      ))}
    </TableBodyContainer>
  )
}

export { TableBody }

const TableBodyContainer = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;

  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f516;
  }

  ::-webkit-scrollbar {
    width: 1px;
    background-color: #f5f5f512;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #413a3a2b;
  }
`
