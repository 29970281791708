import styled from "styled-components"
import { useStore } from "@state/store"

export const poundFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const FavouritesInfoCard = ({ unit, compare = false }) => {
  const contentControls = useStore((s) => s.contentControls)
  const currentSection =
    contentControls?.categories?.[contentControls?.index]?.toLowerCase()

  // this is for compare mode
  if (!Array.isArray(unit)) {
    unit = [unit]
  }

  const selectedUnit = unit[0]

  if (!selectedUnit || contentControls?.index === null) return null

  let name = selectedUnit?.unitName
  name = name.split("-")
  let block = name[0]
  name = Number(`${name[1]}${name[2]}`)

  let type = selectedUnit?.unitType
  type = type.split(" ").filter((txt) => txt !== "TYPE")
  type = type.join("").replace("1-STUDIO", "1-ST")

  return (
    <Wrapper id="favouritesInfoCard" compare={compare} section={currentSection}>
      <div className="specsContainer">
        <div className="spec" style={{ color: "var(--primary)" }}>
          Floor {selectedUnit?.floor}
        </div>
        <div className="unit">
          <div className="unit-name">Unit {name}</div>
          {selectedUnit?.beds < 1 ? (
            <div className="bedroom">Studio</div>
          ) : (
            <div className="bedroom">{selectedUnit?.beds} bedroom</div>
          )}
        </div>
        <svg
          width="28"
          height="22"
          viewBox="0 0 28 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginBottom: "1rem" }}
        >
          <path d="M26.16 0H1L13.86 21.86L26.16 0Z" fill="#ffffff" />
        </svg>

        <div className="spec coloredSpec">
          <div>Block</div>
          <div className="spec2">{block}</div>
        </div>
        <div className="spec coloredSpec">
          <div>Aspect</div>
          <div className="spec2">{selectedUnit?.aspect}</div>
        </div>
        <div className="spec coloredSpec">
          <div>Type</div>
          <div className="spec2">{type}</div>
        </div>
      </div>
      <div className="financialContainer">
        <div className="spec">
          <div>Aspect</div>
          <div className="spec2">{selectedUnit?.aspect}</div>
        </div>
        <div className="spec">
          <div>Beds</div>
          <div className="spec2">{selectedUnit?.beds}</div>
        </div>
        <div className="spec">
          <div>Floor</div>
          <div className="spec2">{selectedUnit?.floor}</div>
        </div>
        <div className="spec">
          <div>Price</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.price)}
          </div>
        </div>
        <div className="spec">
          <div>Monthly Rent</div>
          <div className="spec2">
            £{selectedUnit?.["Monthly Rent (SO)"] ?? "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Monthly Mortgage</div>
          <div className="spec2">
            £{selectedUnit?.["Monthly Mortgage"] ?? "Coming Soon"}
          </div>
        </div>
        <div className="spec">
          <div>Total Monthly Cost</div>
          <div className="spec2">
            £{selectedUnit?.["Total Monthly Costs"] ?? "Coming Soon"}
          </div>
        </div>
        <div className="spacer"></div>
        <div className="financialContainer">
          {dimensionKeys.reduce((acc, key, index, array) => {
            if (selectedUnit?.[key]) {
              // Add the current spec element
              acc.push(
                <div className="spec" key={`dim-${key}`}>
                  <div>{key}</div>
                  <div className="spec2 dimension">{selectedUnit[key]}</div>
                </div>,
              )

              // Add a spacer after every 2nd element (except the last one)
              if (index % 2 === 1 && index < array.length - 1) {
                acc.push(<div className="spacer" key={`spacer-${index}`} />)
              }
            }
            return acc
          }, [])}
        </div>
      </div>
    </Wrapper>
  )
}

const dimensionKeys = [
  "Kitchen",
  "Kitchen/Dining",
  "Living",
  "Living/Dining",
  "Bedroom 1",
  "Bedroom 2",
  "Total Internal Area (sqft)",
  "Total Internal Area (sqm)",
  "Total Terrace Area (sqft)",
  "Total Terrace Area (sqm)",
]

export default FavouritesInfoCard

const Wrapper = styled.div<{ compare: boolean; section: string }>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  padding: ${(props) => (props.compare ? "1em" : "5em")};
  gap: 3em;
  width: ${(props) => (props.compare ? "80%" : "100%")};

  .unit {
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.compare ? "column" : "row")};
    justify-content: space-between;
    align-items: ${(props) => (props.compare ? "flex-start" : "center")};
    font-size: ${(props) => (props.compare ? "1em" : "4em")};
    font-weight: 900;
    margin-bottom: 2rem;
    color: #282828;
    line-height: 1;

    .unit-name {
      font-size: ${(props) => (props.compare ? "2em" : "1em")};
    }

    .bedroom {
      font-family: secondaryFont;
      color: #fff;
      text-transform: capitalize;
      line-height: 1;
      font-size: ${(props) => (props.compare ? "1em" : "3rem")};
      font-weight: 400;
      font-size: ${(props) => (props.compare ? "1.5em" : "1em")};
    }
  }

  .spec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 200;
  }

  .specsContainer {
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1rem;

    .spec2 {
      font-weight: 800;
    }

    .spacer {
      height: 1rem;
    }
  }

  .financialContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 0.5rem;
    font-size: 1rem;

    .spec {
      width: 100%;
      display: flex;
      font-weight: 100;
    }

    .spec2 {
      font-weight: 800;
    }
    .spacer {
      height: 1rem;
    }
  }

  .spec2 {
    margin-left: auto;
    letter-spacing: 2px;
  }
`
